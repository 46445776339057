import {  useEffect, useState } from "react"
import { collection, getDocs } from "firebase/firestore";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import './list.css'
import { setShowForm } from "../reducers/slicePanel";

import { deleteAllImages, deleteFile } from "./adminModule";

export const List = ({fun, tab, db, showForm, setAdd, add, setElementId, setChangeElement,changeElement}) => {
    
    const [data,setData] = useState([])

    useEffect(()=>{
        setData(prevData => {return []})
        setAdd(false)
        console.log(data)

        const fetchData = async () => {
            let item;
            if (tab === 'Series') {
                item = 'series-es';
            } else {
                item = 'paints-es';
            }
            try{
                await getDocs(collection(db, item))
                .then((res)=>{
                    console.log(res.docs)
                    const querySnapshot = res
                    const newData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                                setData(prevData => {
                                    // Filtrar datos que ya existen en el estado
                                    const filteredData = newData.filter(newItem => !prevData.some(oldItem => oldItem.id === newItem.id));
                                    console.log(filteredData)
                                    return [...prevData, ...filteredData];
                                });
                })
            } catch(err){
                console.log(err)
            }
            
        };

        fetchData();
    }, [tab, add===true, changeElement===true,])

    if(data){

        return(
            <div className="list">
                {
                    data ? 
                    data.map((item)=>{
                        return(
                            tab==='Series'?
                            <div key={item.id}  className="card-panel">
                                <div className='header'>
                                    <EditIcon className="icon"/>
                                    <DeleteOutlineIcon  className="icon"/>
                                </div>
                                <div className="img">
                                    <img src={item.images[0]} />
                                </div>
                                <div className="body">
                                    <p>{item.title}</p>
                                    <div className="detail">
                                        <p>{item.description}</p>
                                        
                                    </div>
                                </div>
                            </div> : 
                            <>
                                <div key={item.id}  className="card-panel">
                                <div className='header'>
                                    <EditIcon onClick={
                                        ()=>{
                                            console.log('edit',item.id)
                                            setShowForm(true)
                                            setElementId(item.id)
                                            setChangeElement(true)
                                        }
                                    } className="icon"/>
                                    <DeleteOutlineIcon onClick={async()=>{
                                        console.log('clock')
                                        await deleteAllImages('paints', item.id)
                                        await deleteFile('paints', item.id)
                                        .then(res=>{
                                            setChangeElement(true)
                                        })
                                    }} className="icon"/>
                                </div>
                                <div className="img">
                                    <img src={item.images[0]} />
                                </div>
                                <div className="body">
                                    <p>{item.title}</p>
                                    <div className="detail">
                                        <div>
                                        <p>{item.technic} sobre</p>
                                        <p>{item.material}</p>

                                        </div>
                                        <p>{item.size}</p>
                                        
                                    </div>
                                </div>
                            </div>
                            </>
                        ) 
                    }) 
                    : <></>
                }
            </div>
        )
    }

}