import { useDispatch, useSelector } from "react-redux"
import { handleCartItem, toggleCartState } from "../../reducers/userReducer"
import { Carousel } from "react-bootstrap"
import './paintDetail.css'
import { useEffect, useState } from "react"
import { ViewerCarousel } from "../viewer/Viewer"
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { toggleViewerState } from '../../reducers/userReducer';
import { Spinner } from "../spinner/Spinner"
import { normalizeString } from "../../normalize"
import { useParams } from "react-router-dom"


export const PaintDetail = ({paint}) => {

    const [carouselIdx, setCarouselIdx] = useState(0)
    const [imageIdx, setImageIdx] = useState()
    const cartState = useSelector(state => state.user.cartState)
    const viewerState = useSelector(state => state.user.viewerVisible)
    const lenState = useSelector(state=>state.user.lenguage)
    const dispatch = useDispatch()
    const {serieName,paintName} = useParams()
    
    useEffect(()=>{
       
    },[lenState, serieName,paintName, paint])

    const handleAddCartItem = (paint) => {
  
        dispatch(handleCartItem({act:'add',item:paint}))
        dispatch(toggleCartState())
    }

    return (
        <div className='paint-container'>
            {
                paint ? 
                <div className='paint-body'>
                        <ViewerCarousel setIdx={setImageIdx} state={viewerState} idx={imageIdx} images={paint.data.images} />
                        <div className="carousel-container">
                        <button onClick={()=>{dispatch(toggleViewerState());
                                            setImageIdx(0);}}>{lenState==='es'?"Ver en galería":'See in gallery'} <ZoomOutMapIcon/></button>
                            <Carousel indicators={false} interval={viewerState?null:3500} onSelect={(e)=>{setCarouselIdx(e)}} activeIndex={carouselIdx}   pause={false} className="img-visualize">
                                {
                                    paint ?
                                    paint.data.images.map((item,idx)=>{

                                        return(
                                            <Carousel.Item onClick={()=>{dispatch(toggleViewerState());
                                            setImageIdx(idx);}}>
                                                
                                                <img alt="Ver" className="img" src={paint.data.images[idx]} />
                                            </Carousel.Item>
                                                
                                        )
                                    })
                                    : <></>
                                }
                            </Carousel>
                           
                        </div>
                        
                        <div className='info'>
                            <div className='head'>
                                
                                <h2>{normalizeString(serieName) + '   |   ' + paint.data.year}</h2>
                                <div className="title">
                                <h1>{paint.data.title.charAt(0).toUpperCase()+paint.data.title.slice(1)}</h1>{paint.data.sale===false? <div className="circle"></div> : <></>}
                                </div>
                                {paint.data.sale===false?<p>{lenState==='es'?"Colección privada":'Private collection'}</p> : <></>}
                            </div>
                            <div className="body">
                                <div className='detail'>
                                    <div className='detail-item'>
                                        <p>{lenState==='es'?"Medida:":'Size:'}</p>
                                        <p>{paint.data.size}</p>
                                    </div>
                                    <div className='detail-item'>
                                        <p>{lenState==='es'?"Técnica:":'Technic:'}</p>
                                        <p>{paint.data.technic.charAt(0).toUpperCase()+paint.data.technic.slice(1)}</p>
                                    </div>
                                    <div className='detail-item'>
                                        <p>{lenState==='es'?"Material:":'Material:'}</p>
                                        <p>{paint.data.material.charAt(0).toUpperCase()+paint.data.material.slice(1)}</p>
                                    </div>
                                </div>
                                {
                                    paint.data.sale===true?
                                    <div className='price'>
                                        <p>{`USD $ ${paint.data.price}.00`}</p>
                                    </div>
                                    :<></>
                                }

                            </div>
                            {
                                paint.data.sale===true?
                                <div className='paint-buttons'>
                                    <button className="btn" onClick={()=>{
                                        handleAddCartItem(paint)
                                    }}>{lenState==='es'?"Agregar al carrito":'Add to cart'}</button>
                                </div>
                                : <></>
                            }
                        </div>
                    </div>
                    : <Spinner/>
            }

                    
        </div>
    )
}