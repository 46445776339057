import React,  {useEffect, useState} from "react"
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { setUser, logOut, handleHelmet } from '../reducers/userReducer'; // Importa tus acciones

import { Banner } from "../components/banner/Banner";
import { BioCard } from "../components/bioCard/BioCard";
import { PaintsSlider } from "../components/paintsSlider/PaintsSlider";
import { BuyInfoCard } from "../components/buyInfoCard/BuyInfoCard";
import { ContactCard } from "../components/contactCard/ContactCard";

import contentEs from '../content-es.json'
import contentEn from '../content-en.json'
import { dataManagear } from "../controllers/dataManagear";
import { addDocumentToCollection, getDocument, getPageContent } from "../dataModule";
import { useLocation } from "react-router-dom";
import { Contact } from "./Contact";
import { HelmetComponent } from "../Helmet";



export const Home = () => {

    const dispatch = useDispatch();
    const lenState = useSelector(state=>state.user.lenguage)
    const location = useLocation()
    
    const [pageDate,setPageDate] = useState()
 

    useEffect(()=>{
        window.scrollTo(0,0)
    

       getPageContent(location.pathname, lenState)
       .then(res=>{
        if(res.status===200){
            setPageDate(res.data)
        }
       
       })
    }, [lenState])

    return (
        <div className="home page">
            <HelmetComponent title={lenState==='es'?'Inicio':'Home'} description={'Artista plástico nacido en Santa Fé, Argentina...'}/>
            <Banner/>
            <PaintsSlider/>
            <BioCard/>
            <BuyInfoCard/>
            <Contact/>
        </div>
    )
}