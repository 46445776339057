import { useNavigate } from 'react-router-dom';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import './paintCard.css';
import { Link } from 'react-router-dom';
import { normalizeUrl } from '../../normalize';
import { useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { getSerieNameById } from '../../dataModule';

export const PaintCard = ({ data }) => {
  const navigate = useNavigate();
  const lenState = useSelector(state => state.user.lenguage);
  const [serieName, setSerieName] = useState('');
  const previousDataRef = useRef(data);

 useEffect(() => {
  if (data && data.serie) {
    getSerieNameById(data.serie, lenState, data.title).then((res) => {
      if (res.status === 200) {
        setSerieName(res.serieName);
      }
    });
  }
}, [data, lenState]);

  useEffect(() => {
    // Actualiza el previousDataRef al nuevo data
    previousDataRef.current = data;
  }, [data]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Esto hace que el desplazamiento sea suave
    });
  };

  const handleNavigate = () => {
    if (serieName && data.title) {
      navigate(`/${normalizeUrl(serieName)}/${normalizeUrl(data.title)}`);
      scrollToTop();
    }
  };

  return (
    <Card
      onClick={handleNavigate}
      className='paint-card'
      variant='outlined'
      sx={{
        width: '95%',
        backgroundColor: `rgb(255,255,255)`,
        border: 'none',
        borderRadius: '0.5rem',
      }}
    >
      <div
        className='img-container'
        style={data ? { backgroundImage: `url('${data.images[0]}')` } : ''}
      ></div>
      <CardContent className='content'>
        <Typography className='title' level='title-md'>
          <h3>{data.title.charAt(0).toUpperCase() + data.title.slice(1)}</h3>
          <div className='detail'>
            <p>
              {`${data.technic.charAt(0).toUpperCase() + data.technic.slice(1)}${lenState=='es'?' sobre ':' in '}${data.material.charAt(0).toUpperCase() + data.material.slice(1)}`}
            </p>
            <p>{data.size}</p>
          </div>
        </Typography>

        <Link
          to={serieName && data ? `/${normalizeUrl(serieName)}/${normalizeUrl(data.title)}` : ''}
          onClick={() => {
            window.scroll(0, 0);
          }}
          className='link'
          overlay
          underline='none'
        >
          {lenState === 'es' ? 'Ver cuadro' : 'See Paint'}
        </Link>
      </CardContent>
      <div className='card-footer'>
        <div className='footer-item'>
          <p>{data.year}</p>
        </div>
        <div className='footer-item'>
          <p>{serieName}</p>
        </div>
      </div>
    </Card>
  );
};
