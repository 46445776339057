import { useDebugValue, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Carousel from 'react-bootstrap/Carousel';

import './banner.css'

import { listCollection } from '../../dataModule';
import { Spinner } from '../spinner/Spinner';
import { SerieCard } from '../serieCard/SerieCard';
import { useSelector } from 'react-redux';


export const normalizeUrl = (title) => {
    return title.toLowerCase().replace(/\s+/g, '-');
}
export function shortDescription(cadena, longitudDeseada) {
    const palabras = cadena.split(' ');

    // Verificar si la cantidad de palabras es menor o igual a la longitud deseada
    if (palabras.length <= 10) { // Puedes cambiar 10 por cualquier número que desees
        return capitalizarPrimeraLetra(cadena);
    } else {
        // Tomar solo las palabras hasta la longitud deseada y unirlas nuevamente
        return `${capitalizarPrimeraLetra(palabras.slice(0, longitudDeseada).join(' '))}...`;
    }
}

// Función para capitalizar la primera letra de una cadena
function capitalizarPrimeraLetra(cadena) {
    return cadena.charAt(0).toUpperCase() + cadena.slice(1);
}


export const Banner = ()=>{
    
    const [series, setSeries] = useState()
    const [windowWidth,setWindowWidth] = useState()
    const navigate = useNavigate()
    const lenState = useSelector(state=>state.user.lenguage)



    useEffect(()=>{
        
        listCollection(`series-${lenState}`).
        then((res)=>{
      
            setSeries(res.list)
        
        })
        setWindowWidth(window.innerWidth)
    }, [lenState])


    return(
        
            <div>
                {
                    series ?
                    <Carousel interval={3000} pause={false} className='banner' indicators={false}>
                        {
                            series ? 
                            series.map((item,idx)=>{
                                return(
                                    <Carousel.Item className='banner-item'>
                                        <div  className='content'>
                                            <SerieCard title={item.data.title} description={item.data.description} images={item.data.images}/>
                                           </div>
                                    </Carousel.Item> 
                                )
                            })
                            : <></>
                        }
                    
                     
                    </Carousel> : <Spinner/>
                }

                    
            </div>
       
    )
} 