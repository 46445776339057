import { useState, useEffect } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import './contact.css'

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';

export const ContactCard = () => {
    const [copied, setCopied] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const lenState = useSelector(state => state.user.lenguage);

    useEffect(() => {
        // Detectar si el usuario está en un dispositivo móvil
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android|iPad|iPhone|iPod/.test(userAgent.toLowerCase())) {
            setIsMobile(true);
        }
    }, []);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000); // Reiniciar el estado de "copied" después de 2 segundos
    };

    let email = "gabyvillot@gmail.com";
    let subject = "Nueva consulta";
    let body = "Hola, mi nombre es...";

    if(lenState === 'es'){
        subject = "Nueva consulta";
        body = "Hola, mi nombre es...";
    } else if(lenState === 'en'){
        subject = "New query";
        body = "Hi, my name is...";
    }

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    const emailLink = isMobile ? mailtoLink : gmailLink;

    return (
        <div className="contact-container">
            {copied && <span className={copied ? 'notification show' : 'notification'}>Texto copiado correctamente!</span>}
            <CopyToClipboard text='gabyvillot@gmail.com'>
                <div className="contact-item" onClick={handleCopy}>
                    <a href={emailLink} target='blank'>
                        <EmailIcon className='icon' />
                        <p>gabyvillot@gmail.com</p>
                    </a>
                    <ContentCopyIcon className='copy-icon'/>
                </div>
            </CopyToClipboard>
            <div className="contact-item" onClick={() => { window.open('https://www.instagram.com/arte.villot/?hl=es-la', '_blank') }}>
                <a>
                    <InstagramIcon className='icon'/>
                    <p>@arte.villot</p>
                </a>
                <PersonAddIcon className='copy-icon'/>
            </div>
            <div className="contact-item" onClick={() => { window.open('https://www.facebook.com/gabrielalejandro.villot', '_blank') }}>
                <a>
                    <FacebookIcon className='icon'/>
                    <p>gabrielalejandro.villot</p>
                </a>
                <PersonAddIcon className='copy-icon'/>
            </div>
        </div>
    );
}
