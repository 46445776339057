import React from 'react';
import './WppButton.css'; // Importamos los estilos

const WppButton = () => {
    const handleClick = () => {
        window.open('https://wa.me/5493425081974', '_blank'); // Reemplaza el número por el tuyo
    };

    return (
        <div className="whatsapp-button" onClick={handleClick}>
            <img
                src="/images/wp-icon.png" /* Ruta de la imagen local */
                alt="WhatsApp"
                className="whatsapp-icon"
            />
        </div>
    );
};

export default WppButton;
