import './serieCard.css'
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { normalizeUrl, shortDescription } from '../banner/Banner';
import axios from 'axios';

export const SerieCard = ({code,title,description,images})=> {

    const navigate = useNavigate()

    const [ len, setLen] = useState()
    const lenState = useSelector(state=>state.user.lenguage)
    const [traduction, setTraduction] = useState({
        title,
        description,
    })

    useEffect(()=>{

    },[lenState])

    return (
        <div className="serie-card">
            <div  className='content'>
                <div className='col'>
                    <div className='img-container'>
                        <img onClick={()=>{navigate(`/${normalizeUrl(title)}`)}} className='img' src={images} ></img>
                    </div>

                </div>

                <div className='item-info'>
                    <p className='cat'>{lenState==='es'?"SERIE":"COLLECTION"}</p>
                    <div className='title'>
                        <h3>{title}</h3>
                    </div>
                    <div className='description'>
                        <p>{shortDescription(description, 22)}</p>
                    </div>
                    <div className='btn-container'>
                        <Link to={`/${normalizeUrl(title)}`} state={code} className='btn'>{lenState==='es'?"Ver serie":"See collection"}</Link>
                    </div>
                </div>

            </div>
        </div>
    )
}