import { createSlice } from '@reduxjs/toolkit';

const adminPanelSlice = createSlice({
  name: 'adminPanel',
  initialState: {
    selectedTab: 'paints',
    showForm: false,
    add: false,
    edit:false,
    elementId: null,
    formAction: 'add',

    images: [],
    imgsLoad: false,
    deleteImg: false,



  },
  reducers: {
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setShowForm: (state, action) => {
      
      console.log(state,action)
      state.showForm = action.payload;
    },
    setAdd: (state, action) => {
      state.add = action.payload;
    },
    setEdit: (state, action) => {
      state.edit = action.payload;
    },
    setElementId: (state, action) => {
      state.elementId = action.payload;
    },
    setFormAction: (state, action) => {
      state.formAction = action.payload;
    },
    resetForm: (state) => {
      state.showForm = false;
      state.add = false;
      state.elementId = null;
      state.formAction = 'add';
    }
  },
});

export const {
  setSelectedTab,
  setShowForm,
  setAdd,
  setEdit,
  setElementId,
  setFormAction,
  resetForm
} = adminPanelSlice.actions;

export default adminPanelSlice.reducer;
