import { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './paintsSlider.css';
import { PaintCard } from '../paintCard/PaintCard';

import { useWindowSize } from '../../UseWindowSize'; // Asegúrate de importar el hook correctamente
import { useMediaQuery } from 'react-responsive';
import { listCollection } from '../../dataModule';
import { Spinner } from '../spinner/Spinner';
import { useSelector } from 'react-redux';

export const PaintsSlider = () => {
    const [paints, setPaints] = useState([]);
    const lenState = useSelector(state => state.user.lenguage);
    const windowSize = useWindowSize();
    const isMobile = windowSize.width <= 767; // Utiliza windowSize para determinar si es móvil

    useEffect(() => {
        const fetchPaints = async () => {
            const res = await listCollection(`paints-${lenState}`);
            const listFilter = filterList(res.list)
            setPaints(listFilter.slice(0, 15));
            
        };

        fetchPaints();
    }, [lenState]);

    const filterList = (list) => {
        return list.sort((a, b) => b.data.year - a.data.year);
    };
    const sliderSettings = {
        dots: false,
        arrows: !isMobile,
        infinite: paints.length > 3,
        speed: 500,
        slidesToShow: isMobile ? 1 : (paints.length >= 4 ? 4 : paints.length), // Ajusta slidesToShow según el tamaño de la ventana
        slidesToScroll: 1,
        autoplay: paints.length > 3,
        autoplaySpeed: 3000,
        pauseOnHover: false
    };

    return (
        <div className='paints-slider'>
            {paints.length ? (
                paints.length === 1 ? (
                    <div className='single-paint'>
                        <PaintCard id={paints[0].id} className='paint-card' data={paints[0].data} />
                    </div>
                ) : (
                    <Slider className='slider' {...sliderSettings}>
                        {paints.map((paint, index) => (
                            <PaintCard id={paint.id} key={index} className='paint-card' data={paint.data} />
                        ))}
                    </Slider>
                )
            ) : (
                <Spinner />
            )}
        </div>
    );
};
