import './buyInfoCard.css'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import EmailIcon from '@mui/icons-material/Email';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getDocument } from '../../dataModule';

export const BuyInfoCard = () => {
  const [ len, setLen] = useState()
  const lenState = useSelector(state=>state.user.lenguage)
  const location = useLocation()
  const [data,setData] = useState()

  useEffect(()=>{
    setLen(lenState)
    getDocument('content', lenState)
        .then(res=>{
          if(res.status===200){
  
                setData(res.data.data)
            }
        })
  },[lenState])

  return (  
    <div className="buy-info">
      <h3>{data ? data.components.buyInfoCard.title : ''}</h3>
      <div className="items-info">
        {
          data ?
          data.components.buyInfoCard.items.map((item,idx)=>{
            
            return (
              <div className="item"> 
                {
                  idx === 0 ? <ShoppingCartCheckoutIcon className='icon'/> : 
                  idx === 1 ? <EmailIcon className='icon'/> :
                  idx === 2 ? <LocalShippingIcon className='icon'/> 
                  : ''
                }
                <p>{item}</p>
              </div>
            )
          })
          : ''
        }
      </div>
    </div>
  )
}