import { useEffect, useState } from "react"
import { PaintCard } from "../paintCard/PaintCard"

import   './paintsList.css'
import { PaintCardSerie } from "../paintCardSerie/PaintCardSerie"
import { getPaintsInSerie, listCollection } from "../../dataModule"
import { normalizeUrl } from "../../normalize"
import { Spinner } from "../spinner/Spinner"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

export const PaintsList = ({path, serieId, serieName})=> {
    
    const [paints,setPaints] = useState()
    const lenState = useSelector(state=>state.user.lenguage)
    const location = useLocation()
    useEffect(()=>{
        //aca traigo la data de los cuadros segun el id de la serie
    
        getPaintsInSerie(serieId, lenState)
        .then((res)=>{
     
            if(res.status===200){
                setPaints(filterList(res.paints))
            }
            

        })
 
        
    }, [lenState])
    const filterList = (list) => {
        return list.sort((a, b) => b.data.year - a.data.year);
    };
    return (
        <div className="paints-list-container">
            {
                paints ? 
                paints.map((paint,idx)=>{
                    return(
                        <PaintCardSerie serieName={serieName} id={paint.id} data={paint.data}/>
                    )
                }) : <Spinner/>
            }
        </div>
    )
}