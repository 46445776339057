import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector,useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const HelmetComponent = ({title, description}) => {
  
  const helmet = useSelector(state=>{return state.user.helmet})
  const location = useLocation()
  const lenState = useSelector(state => state.user.lenguage)
  const [siteName, setSiteName] =  useState()
  const [helmetTags, setHelmetTags] = useState({title:'',description:'',})
  const [pathname,setPathName] = useState()
  
  useEffect(()=>{
    setPathName(location.pathname)

    if(lenState==='es'){setSiteName('Gabriel Villot Arte')}
    if(lenState==='en'){setSiteName('Gabriel Villot Art')}
    setHelmetTags({title:title, description:description})

    if(title&&description){

    }    
 
  },[lenState, pathname, title, description])

  return (
    <div>
      {
        title || title && description ?
        <Helmet>
          
          <title>{`${title.charAt(0).toUpperCase()+title.slice(1)+' | '+siteName}`}</title>
          <meta property="og:title" content={`${title+' | '+siteName}`}></meta>
          <meta property="og:description" content={description}></meta>
          <meta property="og:url" content={`https://www.gabrielvillot.com.ar${location.pathname}`}></meta>

        </Helmet>
        : ''
        
      }
    </div>
  );
};


