import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import './footer.css';
import { useSelector } from "react-redux";
import { getDocument } from "../../dataModule";

export const Footer = () => {
    const [pages, setPages] = useState([]);
    const lenState = useSelector(state => state.user.lenguage);
    const location = useLocation();

    useEffect(() => {
        getDocument('content', lenState)
        .then(res => {
            if (res.status === 200) {
                const navItems = res.data.data.header.nav;
                    setPages(navItems);
                }
            });
    }, [lenState]);

    return (
        <div className="footer" style={{ display: window.location.pathname === '/admin/login' || window.location.pathname === '/admin/panel' || window.location.pathname === '/admin' ? 'none' : 'block' }}>
            <ul>
                {
                    pages.length > 0 &&
                    pages.map((page, idx) => {
                        return(

                        <li key={idx}>
                            <Link 
                                to={page.path}
                                className={location.pathname === page.path ? 'select' : ''}
                            >
                                {page.title}
                            </Link>
                        </li>
                        )
                    })
                }
            </ul>
            <div className="footer-footer">
                <p>Tres Punto Cero®</p>
            </div>
        </div>
    );
};
