import { getStorage,ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { getFirestore, collection, addDoc,getDoc, getDocs, deleteDoc, updateDoc, doc } from "firebase/firestore";
import { firebaseApp } from '../firebaseApp';

export const deleteAllImages = async (col, id) => {
    try {
        const file = await getFile(col, id);
        
        // Verificar si se encontró el documento
        if (file.status !== 200) {
            return { status: 404, message: "Documento no encontrado" };
        }

        const data = file.data;
        const images = data.images || [];

        // Eliminar cada imagen de Firebase Storage
        for (const imageUrl of images) {
            await deleteImageByUrl(imageUrl);
        }

        // Actualizar los datos del documento para eliminar las URLs de imágenes
        await updateFile(col, id, { ...data, images: [] });

        return { status: 200, message: "Todas las imágenes eliminadas correctamente" };
    } catch (err) {
        return { status: 403, err: err };
    }
}

export const deleteImageAndData = async (col, id, imageUrl) => {

    try {
        const db = getFirestore(firebaseApp);
        const docRef = doc(db, col, id);

        const file = await getFile(col,id)
        
        // Obtener los datos actuales de la pintura
        console.log(file)
        if (file.status!==200) {
            return { status: 404, message: "Documento no encontrado" };
        }

        const data = file.data;

        // Eliminar la imagen de la lista de imágenes
        const updatedImages = data.images.filter(img => img !== imageUrl);

        // Actualizar los datos de la pintura en la base de datos
        await updateFile(col,id,{...data, images:updatedImages})

        // Eliminar la imagen de Firebase Storage
        await deleteImageByUrl(imageUrl);

        return { status: 200, message: "Imagen eliminada correctamente y datos actualizados" };
    } catch (err) {
        return { status: 403, err: err };
    }
}    
export const deleteImageByUrl = async (imageUrl) => {
    try {
        const storage = getStorage(firebaseApp);
        const imageRef = ref(storage, imageUrl);

        // Delete the file
        await deleteObject(imageRef);

        return { status: 200, message: "Imagen eliminada correctamente" };
    } catch (err) {
        return { status: 403, err: err };
    }
}
export const addImg = async (data, cat) => {
    try {
        const storage = getStorage(firebaseApp)
        const urlsContainer = []
        for (const img of data) {
            const imgRef = ref(storage, `images/${cat}/${img.name}`);
            await uploadBytes(imgRef, img);
            await getDownloadURL(imgRef)
            .then((res)=>{
                urlsContainer.push(res)
            })
            
        }
        return {status:200, urls:urlsContainer}
    } catch(err){
        return {status:403, err:err}
    }
}

export const addFile = async (col, data) => {
    try {
        const db = getFirestore(firebaseApp)
        const paintRef = await addDoc(collection(db,col), data);
        return {status:200, id:paintRef.id}
    }
    catch(err){

        return {status:403, err}
    }
}
export const deleteFile = async (col, id) => {
    try {
        const db = getFirestore(firebaseApp);
        const docRef = doc(db, col, id);
        await deleteDoc(docRef);
        return { status: 200, message: "Elemento eliminado correctamente" };
    } catch (err) {
        return { status: 403, err: err };
    }
}
export const updateFile = async (col, id, newData) => {
    try {
        const db = getFirestore(firebaseApp);
        const docRef = doc(db, col, id);
        await updateDoc(docRef, newData);
        return { status: 200, message: "Elemento actualizado correctamente" };
    } catch (err) {
        return { status: 403, err: err };
    }   
}
export const getFile = async (col, id) => {
    try {
        const db = getFirestore(firebaseApp);
        const docRef = doc(db, col, id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return { status: 200, data: docSnap.data() };
        } else {
            return { status: 404, message: "Documento no encontrado" };
        }
    } catch (err) {
        return { status: 403, err: err };
    }
}

