import React, { useEffect, useState } from 'react';

import Image from './Image';
import './imageList.css'
import { getFile } from './adminModule';

const ImageList = ({ images, imgsLoad, deleteImg, setDeleteImg, elementId, selectedTab }) => {


  const getImgsUlrs = async () => {
    const data = await getFile('paints', elementId)
    return data
  }

  useEffect(()=>{
    console.log(images)
  },[images, deleteImg===true, imgsLoad===true])
  
      
    if(images){
            return (
            <div className="image-list">
                {images.map((image, index) => (
                <Image images={images} selectedTab={selectedTab} id={elementId}  src={image} setDeleteImg={setDeleteImg} />
                ))}
            </div>
            );

    }
};


export default ImageList;
