import React, { useState, useEffect } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import { useSelector, useDispatch } from "react-redux";
import { toggleViewerState } from "../../reducers/userReducer";
import './viewer.css'; // Importa tu archivo CSS

export const ViewerCarousel = ({ images, idx, setIdx }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const viewerState = useSelector((state) => state.user.viewerVisible);

  useEffect(() => {
    setOpen(viewerState);
  }, [viewerState]);

  const handleNext = () => {
    const nextIndex = (idx + 1) % images.length;
    setIdx(nextIndex);
  };

  const handlePrev = () => {
    const prevIndex = idx === 0 ? images.length - 1 : idx - 1;
    setIdx(prevIndex);
  };

  return (
    <>
      <Lightbox
        index={idx}
        open={open}
        plugins={[Zoom]}
        close={() => {
          setOpen(false);
          dispatch(toggleViewerState());
        }}
        onNext={handleNext}
        onPrev={handlePrev}
        slides={images.map((image) => {
          return { src: image };
        })}
        animation={{ zoom: 100 }}
        zoom={{
          scrollToZoom: true,
          maxZoomPixelRatio: 2,
          zoomInMultiplier: 0.2,
          doubleTapDelay: 300,
          doubleClickDelay: 500,
          doubleClickMaxStops: 2,
          keyboardMoveDistance: 50,
          wheelZoomDistanceFactor: 200,
          pinchZoomDistanceFactor: 200,
          disablePinchToZoom: false,
          disableDoubleClickZoom: false,
        }}
      />
    </>
  );
};
