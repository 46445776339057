import  {initializeApp} from "firebase/app";

const firebaseConfig2 = {
  apiKey:`${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain:`${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
  projectId:`${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  storageBucket:`${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
  messagingSenderId:`${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
  appId:`${process.env.REACT_APP_FIREBASE_APP_ID}`,
  measurementId:`${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`
};
const firebaseConfig = {
  apiKey: "AIzaSyB96HJUrnOLrX-Dy1BCet63JbSHpvqZOBc",
  authDomain: "gabriel-villot.firebaseapp.com",
  projectId: "gabriel-villot",
  storageBucket: "gabriel-villot.appspot.com",
  messagingSenderId: "46337209667",
  appId: "1:46337209667:web:4307ef514f7421cd1c2bf6",
  measurementId: "G-SL07TCQ5L5"
};


export const firebaseApp = initializeApp(firebaseConfig);
