import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { normalizeString, normalizeUrl } from '../normalize';

import { PaintDetail } from '../components/PaintDetail.js/PaintDetail';
import { BuyInfoCard } from '../components/buyInfoCard/BuyInfoCard';
import { PaintsSlider } from '../components/paintsSlider/PaintsSlider';
import { getPaint, listCollection } from '../dataModule';
import { Spinner } from '../components/spinner/Spinner';
import { HelmetComponent } from '../Helmet';

export function Paint (){
    const [paint, setPaint] = useState()
    const [paintsList, setPaintsList] = useState()
    const {serieName, paintName} = useParams()
    const lenState = useSelector(state=>state.user.lenguage)
    const location = useLocation()
    const navigate = useNavigate()
    const { user, isLoggedIn, cart } = useSelector(state => state.user);

    
    const handlePaintData = async (a,b,c) => {
        await getPaint(a,b,c)
        .then((res)=>{  
 
            if(res.status===200){
                setPaint(res.paint)
                return 
            }
            else{

                navigate('/')
            }
        })
        
    }
   
    
    useEffect(()=>{ 

        //traer cuadro desde la base de datos con los datos que vienen como parametros
            
        handlePaintData(paintName,serieName,lenState)
     
        
     
    },[serieName, paintName])
    useEffect(()=>{
    
        window.scrollTo(0,0)
        if(paint){
            navigate('/')
        }
    }, [lenState])

        return(
            <div className='page'>
                {
                    paint ?
                    <>
                        <HelmetComponent title={paint.data.title}
                        description={``} />
                        <PaintDetail paint={paint} serieName={serieName} />
                        <BuyInfoCard/>
                        <PaintsSlider/>
                    </> :
                    <Spinner/>

                }
            </div>
        )

   
}