import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './summary.css';
import CloseIcon from '@mui/icons-material/Close';
import { toggleNotState, clearCartItems, toggleSummaryState } from '../../reducers/userReducer';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';

const Summary = () => {
    const dispatch = useDispatch();
    const lenState = useSelector(state => state.user.lenguage);
    const cartState = useSelector(state => state.user.cartState);
    const summaryVisible = useSelector(state => state.user.summaryVisible);
    const notState = useSelector(state => state.user.notificationState.isVisible);
    const [formData, setFormData] = useState({ name: '', email: '', msg: '' });
    const [formPending, setFormPending] = useState(false);

    // Función para calcular el precio total del carrito
    const calculateTotalPrice = () => {
        let total = 0;
        cartState.items.forEach(item => {
            total += item.data.price * item.quantity;
        });
        return total.toFixed(2);
    };

    const resetForm = () => {
        setFormData({ name: '', email: '', msg: '' });
    };

    const handleNot = (text) => {
        dispatch(toggleNotState({ text: text }));
        setTimeout(() => {
            dispatch(toggleNotState({ text: '' }));
        }, 2500);
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormPending(true);

        if (!formData.name || !formData.email || !formData.msg) {
            setFormPending(false);
            handleNot(lenState === 'es' ? 'Por favor, complete todos los campos.' : 'Please fill in all fields.');
            return;
        }

        if (!isValidEmail(formData.email)) {
            setFormPending(false);
            handleNot(lenState === 'es' ? 'Correo electrónico inválido.' : 'Invalid email address.');
            return;
        }

        const data = formData;
        resetForm();

        try {
            const response = await axios.post('/.netlify/functions/resend', { data, items: cartState.items, len: lenState });

            if (response.status === 200) {
                setFormPending(false);
                const msg = lenState === 'es' ? 'Consulta enviada con éxito. Vas a recibir un correo con más información' : 'Query sent successfully. You will receive an email with more information';
                handleNot(msg);
                dispatch(clearCartItems());
                dispatch(toggleSummaryState());
            } else {
                throw new Error('Response status not 200');
            }
        } catch (error) {
            const msg = lenState === 'es' ? 'Hubo un error, vuelve a intentarlo' : 'There was an error, please try again';
            handleNot(msg);
            setFormPending(false);
            dispatch(toggleSummaryState());
        }
    };

    const handleInput = (e) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    return (
        <div className={`summary-overlay ${summaryVisible ? 'visible' : 'hidden'}`}>
            <div className={`summary-container ${summaryVisible ? 'visible' : 'hidden'}`}>
                <div className='summary-header'>
                    <h2>{lenState === 'es' ? 'Resumen' : 'Summary'}</h2>
                    <CloseIcon onClick={() => dispatch(toggleSummaryState())} />
                </div>
                <div className="summary-items">
                    <ul>
                        {cartState.items.map((item, index) => (
                            <li key={index}>
                                <div className='d-block'>
                                    <span>{item.data.title.charAt(0).toUpperCase() + item.data.title.slice(1)}{item.quantity > 1 ? ` (${item.quantity})` : ''} :</span><br />
                                    <span>USD $ {(item.data.price * item.quantity).toFixed(2)}</span>
                                </div>
                                <div className='line'></div>
                            </li>
                        ))}
                    </ul>
                    <div className="total-price">
                        <span>Total:</span>
                        <span>USD ${calculateTotalPrice()}</span>
                    </div>
                </div>
                <div className="summary-form">
                    <h2>{lenState === 'es' ? 'Déjanos tus datos y nos estaremos comunicando' : 'Leave us your information and we will be in touch'}</h2>
                    <form onSubmit={handleSubmit} className='form'>
                        <div className="form-group">
                            <label htmlFor="name">{lenState === 'es' ? 'Nombre:' : 'Name:'}</label>
                            <input value={formData.name} onChange={handleInput} type="text" id="name" name="name" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">{lenState === 'es' ? 'Correo electrónico:' : 'Email:'}</label>
                            <input value={formData.email} onChange={handleInput} type="email" id="email" name="email" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="msg">{lenState === 'es' ? 'Mensaje:' : 'Message:'}</label>
                            <textarea value={formData.msg} onChange={handleInput} id="msg" name="msg" />
                        </div>
                        <button type="submit" disabled={formPending}>
                            {formPending ? <Spinner animation="border" /> : (lenState === 'es' ? 'Enviar' : 'Send')}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Summary;
