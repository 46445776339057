import React, { useEffect, useState } from 'react';

import { addFile, addImg, deleteImageByUrl, getFile, updateFile } from './adminModule';
import { listCollection } from '../dataModule';
import axios from 'axios';
import './paintForm.css'
import ImageList from './ImageList';
import { useSelector, useDispatch } from 'react-redux';
import { setAdd, setEdit, setShowForm } from '../reducers/slicePanel';

// Mapeo personalizado de palabras para traducción
const wordMapping = {
  'tela': 'fabric',
  // Puedes agregar más mapeos aquí según sea necesario
};

// Función para traducir un objeto
const translateObject = async (obj, targetLanguage) => {
const apiKey = process.env.REACT_APP_GOOGLE_TRANSLATE;  // Asegúrate de reemplazar con tu clave API válida

try {
    // Array de promesas de traducción para cada atributo del objeto
    const promises = Object.keys(obj).map(async (key) => {
        let translation = obj[key];

        // Verifica si el valor comienza con un guion bajo (nombre propio)
        if (obj[key].startsWith('_')) {
            translation = obj[key];  // No traducir si es un nombre propio
        } else if (key === 'material' && obj[key] in wordMapping) {
            translation = wordMapping[obj[key]];
        } else {
            // Realiza una solicitud POST a la API de Google Translate
            const response = await axios.post(
                `https://translation.googleapis.com/language/translate/v2`, 
                null, // El body es null en este caso
                {
                    params: {
                        q: obj[key],  // El texto a traducir
                        target: targetLanguage,  // El idioma de destino
                        key: apiKey  // La clave API
                    }
                }
            );
            
            translation = response.data.data.translations[0].translatedText;
        }

        obj[key] = translation;
    });

    // Espera a que todas las promesas de traducción se completen
    await Promise.all(promises);

    return obj;
} catch (error) {
    console.error('Error al traducir:', error);
    throw error;
}
};




export const Form = () => {

    const [button, setButton] = useState('Guardar')
    const [imgsLoad, setImgsLoad] = useState(false)
    const [deleteImg, setDeleteImg] = useState(false)
    const [imgsUrls, setImgsUrls] = useState([])
    const [prevCharge, setPreveCharge] = useState([])
    const [series,setSeries] = useState()
    const [elementId,setElementId] = useState()
    const [formData, setFormData] = useState();

    const adminState = useSelector(state => state.admin)
    const dispatch = useDispatch()

    const paintModel = {
      title: '',
      material:'',
      technic:'',
      size:'',
      price:'',
      stock:'',
      serie:'',
      sale: null, // Nuevo campo para el estado de venta
      year: '', // Nuevo campo para el año del cuadro
      images: imgsUrls   
  }
  useEffect(()=>{

    if(adminState.selectedTab==='paints'){
      setFormData(paintModel)
      if(!series){
        listCollection('series-es')
        .then((res)=>{
          setSeries(res.list)
        })
      }
    } 
  if (adminState.formAction=='edit'){
    try{  
      getFile('paints', elementId)
      .then((res)=>{
        const paint = res.data
     
        setImgsUrls(paint.images)
        setFormData({
          title:paint.title,
          technic:paint.technic,
          material:paint.material,
          size:paint.size,
          price:paint.price,
          stock:paint.stock,
          serie:paint.serie,
          sale:paint.sale,
          year:paint.year,
          images:imgsUrls
        })
      })
      setImgsLoad(true)
    }
    catch(err) {
      console.log(err)
    }
    
    
  }
},[deleteImg===true])


    
const handleTest = async (data, images) => {
  console.log(data);
  let { title, material, technic, serie } = data;

  // Si el título empieza con guion bajo, lo removemos para usar en los archivos
  let newTitle = title.startsWith('_') ? title.substring(1) : title;

  // Verifica si el título original tenía guion para no traducir el nombre propio
  const shouldTranslateTitle = !title.startsWith('_');
  
  // Traduce los otros campos (y el título si no es nombre propio)
  const translationData = shouldTranslateTitle 
      ? { title: newTitle, material, technic }
      : { newTitle, material, technic }; // Aquí el título es el mismo sin guion

  await translateObject(translationData, 'en').then((res) => {
      // Obtiene los valores traducidos (en caso de haber traducido el título)
      const { material, technic } = res;
      const translatedTitle = shouldTranslateTitle ? res.title : newTitle;

      listCollection('series-en').then((res) => {
          const serieFilter = res.list.filter((item) => item.data.key === serie);

          if (serieFilter[0]) {
              // Guarda la versión en español
              addFile(`paints-es`, { ...data, title: newTitle, images }).then((res) => {
                  const key = res.id;
                  const serie = serieFilter[0].id;
                  const dataEn = { ...data, title: translatedTitle, material, technic, serie };

                  // Guarda la versión traducida en inglés
                  addFile(`paints-en`, { ...dataEn, images, key }).then(() => {
                      handleCloseForm();
                  });
              });
          }
      });
  });
};

    const handleAddFile = (lan, data, images) => {
          addFile('paints', {...data, images:images})
         .then(res => {dispatch(setShowForm(false))})
    } 
    const handleEditFile = (id, data, images) => {
      updateFile('paints', id, {...data, images:images})
      .then(res => {fun(false);})
    } 
 

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    const newValue = name === 'sale' ? JSON.parse(value) : value;

    setFormData({ ...formData, [name]: newValue });

  };

  const handleImageChange = async (e) => {
    try {
      setImgsLoad(false)
      setButton('Cargando...')
      const files = Array.from(e.target.files); // Obtener un array de archivos seleccionados
   
      await addImg(files,'paints')
      .then(res => {
        if(res.status===403){console.log(res.err); return res.err} 
        
        res.urls.map(url => prevCharge.push(url))
        prevCharge.map((url)=>{
          const exist = imgsUrls.filter(item => {return url===item})
          console.log(exist)
          if(exist.length!==0){return}
          else{
            imgsUrls.push(url)
          }
        })
        setImgsLoad(true)
        setButton('Guardar')
        console.log(imgsUrls)
      })
    } catch (error) {
      console.error('Error al cargar imágenes:', error);
    }
  };
  

const handleSubmit = async (e) => {
  
  e.preventDefault(); // Prevenir la acción predeterminada del formulario
  
    if(adminState.changeElement===true) {
      console.log(adminState.changeElement)

      handleEditFile(elementId, formData, imgsUrls)
   
    }
    else{

      if(imgsLoad===true){

        handleTest(formData,imgsUrls)
        // handleAddFile(formData,imgsUrls)
           //reset
           setImgsUrls([]);
           setImgsLoad(false);
           setFormData(paintModel);
  
          }
          setAdd(true)
      
        }
         setEdit(false)
    };
    const handleCloseForm = () => {
        
        dispatch(setShowForm(false))
    }

      return (

        <form className='addPaint-form' onSubmit={handleSubmit} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff', padding: '20px', borderRadius: '5px' }}>
      <p>Agregar pintura</p>
      <div className='inputs-container'>
        <div className='column'>
          <div className='label'>
            <label htmlFor="title">Nombre:</label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData ? formData.title : ''}
              onChange={handleChange}
              required
            />
          </div>
          <div className='label'>
              <label htmlFor="year">Año:</label>
              <input
                  type="text"
                  id="year"
                  name="year"
                  value={formData ? formData.year : ''}
                  onChange={handleChange}
                  required
              />
          </div>
          <div className='label'>
            <label htmlFor="material">Material:</label>
            <select id="material" name="material" value={formData ? formData.material : ''} onChange={handleChange} required>
              <option value="default">Seleccione el material</option>
              <option value="tela">Tela</option>
              <option value="papel">Papel</option>
            </select>
          </div>
          <div className='label'>
            <label htmlFor="technic">Técnica:</label>
            <select id="technic" name="technic" value={formData ? formData.technic :''} onChange={handleChange} required>
              <option value="default">Seleccione la técnica</option>
              <option value="Tinta">Tinta</option>
              <option value="Acuarela">Acuarela</option>
              <option value="Tinta y acuarela">Tinta y Acuarela</option>
            </select>
          </div>
          <div className='label'>
            <label htmlFor="size">Tamaño:</label>
            <select id="size" name="size" value={formData ? formData.size : ''} onChange={handleChange} required>
              <option value="default">Seleccione el tamaño</option>
              <option value="30cm x 30cm">30cm x 30cm</option>
              <option value="40cm x 60cm">40cm x 60cm</option>
              <option value="30cm x 60cm">30cm x 60cm</option>
            </select>
          </div>
          
    
        </div>
        <div className='column'>
        <div className='label'>
            <label htmlFor="size">Serie:</label>
            <select id="serie" name="serie" value={formData ? formData.serie :''} onChange={handleChange} required>
              <option value="default">Seleccione la serie</option>
              {
                series ?
                series.map((res)=>{
                  return(
                    <option value={`${res.id}`}>{res.data.title}</option>
                  )
                })
                : ''
              }
            </select>
          </div>
        <div className='label'>
            <label htmlFor="sale">Estado:</label>
            <select id="sale" name="sale" value={formData ? formData.sale :''} onChange={handleChange} required>
                <option value="">Seleccione el estado</option>
                <option value={true}>Cuadro Disponible</option>
                <option value={false}>Cuadro No Disponible</option>
            </select>
        </div>
        {formData && formData.sale === true && (
          <div className='label'>
              <label htmlFor="price">Precio:</label>
              <input
                  type="number"
                  id="price"
                  name="price"
                  value={formData ? formData.price : ''}
                  onChange={handleChange}
                  required
              />
          </div>
      )}
      {formData && formData.sale === true && (
        <div className='label'>
        <label htmlFor="stock">Cantidad en stock:</label>
        <input
          type="number"
          id="stock"
          name="stock"
          value={formData ? formData.stock : ''}
          onChange={handleChange}
          required
        />
      </div>
    )}
          <div className='label'>
            <label htmlFor="images">Imágenes:</label>
            <input
              type="file"
              id="images"
              name="images"
              onChange={handleImageChange}
              multiple
              accept="image/*"
              
              
            />
            {
              imgsLoad===true ?
              <ImageList imgsLoad={imgsLoad} deleteImg={deleteImg} selectedTab={adminState.selectedTab} 
              elementId={elementId} setDeleteImg={setDeleteImg} images={imgsUrls} />
              :''
            }
          </div>
          
        </div>
      </div>
      <div className='btn-container' > 
          <button onClick={handleCloseForm} >Cancelar</button>
        {
          !imgsLoad ?
          <><button disabled>{button}</button></>
          : <button>{button}</button>
        }
      </div>
    </form>
      
      );
};
