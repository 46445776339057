import React, { useState, useEffect } from 'react';
import { getFirestore } from 'firebase/firestore';
import { firebaseApp } from '../firebaseApp';
import { useDispatch, useSelector } from 'react-redux';
import { handleHelmet } from '../reducers/userReducer';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { Form } from './Form';

import './admin.css';
import { PaintForm } from './PaintForm';
import { SerieForm } from './SerieForm';
import { List } from './List';
import { setShowForm } from '../reducers/slicePanel';

const NewPanel = () => {
  const [selectedTab, setSelectedTab] = useState('Pinturas');
  const [add, setAdd] = useState(false);
  const [elementId, setElementId] = useState();
  const [changeElement, setChangeElement] = useState();
  const [formAction, setFormAction] = useState('add');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const adminState = useSelector((state) => state.admin);

  const auth = getAuth();
  const db = getFirestore(firebaseApp);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    sessionStorage.setItem('selectedTab', tab);
  };

  const handleShowForm = () => {
    dispatch(setShowForm(true)); // Cambiar a true para mostrar el formulario
  };

  useEffect(() => {
    dispatch(handleHelmet({ title: 'GV | Panel' }));
    const savedTab = sessionStorage.getItem('selectedTab');
    const isLogin = sessionStorage.getItem('login');
    if (!isLogin) {
      navigate('/admin/login');
    }
    if (savedTab) {
      setSelectedTab(savedTab);
    }

    // Verificar la autenticación al cargar la página
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/admin/login'); // Redirigir al inicio de sesión si el usuario no está autenticado
      }
    });

    return () => unsubscribe();
  }, [adminState, navigate, dispatch, auth]);

  return (
    <div className='admin-panel'>
      <h2>GV | PANEL</h2>
      <div className='content'>
        <div className='sidebar'>
          <ul>
            <li className={`sidebar-item ${selectedTab === 'Series' ? 'select' : ''}`} onClick={() => handleTabClick('Series')}>
              SERIES
            </li>
            <li className={`sidebar-item ${selectedTab === 'Pinturas' ? 'select' : ''}`} onClick={() => handleTabClick('Pinturas')}>
              PINTURAS
            </li>
          </ul>
        </div>
        <div className='list-container'>
          <div className='head'>
            <h3>| {selectedTab}</h3>
            <button onClick={handleShowForm}>Agregar</button>
          </div>
          <List
            changeElement={changeElement}
            setChangeElement={setChangeElement}
            setElementId={setElementId}
            add={add}
            setAdd={setAdd}
            db={db}
            tab={selectedTab}
            showForm={adminState.showForm}
            
          />
        </div>
        {adminState.showForm && <Form />}
      </div>
    </div>
  );
};

export default NewPanel;
