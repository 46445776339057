import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import './serie.css'

import { useDispatch, useSelector } from 'react-redux';
import { PaintsList } from "../components/paintsList/PaintsList";


import { normalizeString, normalizeUrl } from "../normalize";
import { getSerieByPath, listCollection } from "../dataModule";
import { Spinner } from "../components/spinner/Spinner";
import axios from "axios";
import { HelmetComponent } from "../Helmet";


export const Serie = () => {
    const [serieId,setSerieId] = useState()
    const [serie,setSerie] = useState()
    const {serieName} = useParams()
    const { user, isLoggedIn, cart } = useSelector(state => state.user);
    const lenState = useSelector(state => state.user.lenguage)
    const location = useLocation()
    const navigate = useNavigate()
    


    useEffect(()=>{
        window.scrollTo(0,0)
            
    }, [])
    useEffect(()=>{
     
        getSerieByPath(location.pathname,lenState)
        .then((res)=>{
       
            if(res.status===200){
                setSerie(res.data)
                
            }
            else{
                navigate('/')
            }
        })
        
    },[lenState, location.pathname])

    return (
        <div className="page">
            {
                serie ?
                <HelmetComponent title={serieName.charAt(0).toUpperCase()+serieName.slice(1)} description={serie.data.description}/>
                : <></>
            }
            {
                serie ?
                <div className="content">
                <div className="serie-header">
                    <div className="title">
                        <p>{lenState === 'es' ? 'SERIE' : 'COLLECTION'}</p>
                        <h1>{serie.data.title}</h1>
                    </div>
                    <p className="description">{serie.data.description}</p>
                </div>
                <PaintsList  serieId={serie.id} serieName={serie.data.title} />
                </div> :
                <Spinner/>
            }
            
        </div>
    )
       
    
}