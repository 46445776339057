// store.js
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/indexReducer'; // Importa tu combinación de reductores
import { persistReducer, persistStore } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session'; // Importa sessionStorage
import logger from 'redux-logger';

const persistConfig = {
    key: 'root',
    storage: sessionStorage, // Utiliza sessionStorage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(logger),
});

export const persistor = persistStore(store);

export default store;
