// reducers/index.js
import { combineReducers } from 'redux';
import userReducer from './userReducer';
import adminPanelReducer from '../reducers/slicePanel'

const rootReducer = combineReducers({
  user: userReducer,
  admin: adminPanelReducer
  // Agrega más reductores si los tienes
});

export default rootReducer;
    