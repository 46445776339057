import { useEffect, useState } from 'react';
import { ContactCard } from '../components/contactCard/ContactCard';
import './contact.css';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toggleNotState } from '../reducers/userReducer';
import { Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { HelmetComponent } from '../Helmet';

export function Contact() {
    const lenState = useSelector(state => state.user.lenguage);
    const dispatch = useDispatch()
    const navigation = useNavigate()
    const location = useLocation()
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [formStatus, setFormStatus] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        if(location.pathname==='/contacto'||location.pathname==='/contact'){
            if(lenState==='es'){
                navigation('/contacto')
            }
            if(lenState==='en'){
                navigation('/contact')
            }
        }

    }, [lenState]);

    const handleNot = (text) => {
        dispatch(toggleNotState({text:text}))
        setTimeout(()=>{
            dispatch(toggleNotState({text:''}))
    
        }, 2500)
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setFormStatus(true)
            await axios.post('/.netlify/functions/sendMessage', formData)
            .then((res)=>{
            
                if(res.status===200){
                    handleNot(lenState==='es'?'Mensaje enviado':'Message sent');
                    setFormData({
                        name: '',
                        email: '',
                        message: ''
                    });
                    setFormStatus(false)
                } else {
                    handleNot(lenState==='es'?'Hubo un error. Vuelve a intentarlo':'There was a mistake. Please try again');
                }
            })
        } catch (error) {
            handleNot(lenState==='es'?'Hubo un error. Vuelve a intentarlo':'There was a mistake. Please try again');
        }
    };

    return (
        <div className='page'>
            <HelmetComponent title={location.pathname.slice(1).charAt(0).toUpperCase()+location.pathname.slice(2)} 
            description={lenState==='es'?'Enviame un mensaje':'Contact to me'}/>
            <div className='contact'>
                <div className='contact-header' style={{ backgroundImage: `url('https://www.ate.org/data/img_cont/img_artistas/img_gr/302_1522.jpg')` }}>
                </div>
                
                <form className='contact-form' onSubmit={handleSubmit}>
                    <label>
                        {lenState === 'es' ? 'Nombre:' : 'Name:'}
                        <input
                            type='text'
                            name='name'
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                    </label>
                    <label>
                        {lenState === 'es' ? 'Correo electrónico:' : 'Email:'}
                        <input
                            type='email'
                            name='email'
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                    </label>
                    <label>
                        {lenState === 'es' ? 'Mensaje:' : 'Message:'}
                        <textarea
                            name='message'
                            value={formData.message}
                            onChange={handleInputChange}
                            required
                        />
                    </label>
                    {   

                        <button type='submit'>{ formStatus? <Spinner/> : lenState==='es'?'Enviar':lenState==='en'?'Send':''}</button>
                    }
                    {formStatus && <p>{formStatus}</p>}
                </form>
                <ContactCard />
            </div>
        </div>
    );
}
