
import {useNavigate} from 'react-router-dom';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';

import './paintCardSerie.css'
import { Link } from 'react-router-dom';

import { normalizeUrl } from '../../normalize';
import { useEffect } from 'react';
import { Spinner } from '../spinner/Spinner';
import { useSelector } from 'react-redux';

const CardComponent = () => {
  return (
    <Card onClick={ handleNavigate
    } className='paint-card-serie' variant="outlined" sx={{ width: '95%', backgroundColor:`rgb(255,255,255)`, border: 'none',borderRadius:'0.5rem', }}>
        
        <div className='img-container'
          style={{backgroundImage:`url('${data.images[0]}')`}}
        >
            
        </div>
      <CardContent className='content'>
        <Typography className='title' level="title-md">
          <h3 >
            {data.title.charAt(0).toUpperCase()+data.title.slice(1)}
          </h3>
          <div className='detail'>
            <p>{data.technic.charAt(0).toUpperCase()+data.technic.slice(1)} sobre {data.material.charAt(0).toUpperCase()+data.material.slice(1)} </p>
            <p>{data.size}</p>
          </div>

        </Typography>
        
          <Link to={`/${normalizeUrl(serieName)}/${normalizeUrl(data.title)}`}  onClick={()=>{window.scroll(0,0)}} className='link' overlay underline="none">
            Ver cuadro
          </Link>
      </CardContent>
      <div className='card-footer'>
        <div className='footer-item'>
          <p>{2024}</p>
        </div>
        <div className='footer-item'>
          <p>{serieName}</p>
        </div>
      </div>
    </Card>
  )
}

export const PaintCardSerie = ({data, serieName}) => {
    const navigate = useNavigate()
    const lenState = useSelector(state => state.user.lenguage)

    const scrollToTop = () => {
      window.scrollTo({
          top: 0,
          behavior: 'smooth' // Esto hace que el desplazamiento sea suave
      });
  };
    const handleNavigate = ()=>{
      navigate(`/${normalizeUrl(serieName)}/${normalizeUrl(data.title)}`)
      scrollToTop()
    }

    useEffect(()=>{

    }, [data, serieName])


      return (
        <Card onClick={ handleNavigate
        } className='paint-card-serie' variant="outlined" sx={{ width: '95%', backgroundColor:`rgb(255,255,255)`, border: 'none',borderRadius:'0.5rem', }}>
            
            <div className='img-container'
              style={{backgroundImage:`url('${data.images[0]}')`}}
            >
                
            </div>
          <CardContent className='content'>
            <Typography className='title' level="title-md">
              <h3 >
                {data.title.charAt(0).toUpperCase()+data.title.slice(1)}
              </h3>
              <div className='detail'>
                <p>{data.technic.charAt(0).toUpperCase()+data.technic.slice(1)} sobre {data.material.charAt(0).toUpperCase()+data.material.slice(1)} </p>
                <p>{data.size}</p>
              </div>
    
            </Typography>
            
              <Link to={`/${normalizeUrl(serieName)}/${normalizeUrl(data.title)}`} state={{serieName:serieName, paintData:data}}  onClick={()=>{window.scroll(0,0)}} className='link' overlay underline="none">
                {lenState==='es'?'Ver cuadro':'See paint'}
              </Link>
          </CardContent>
          <div className='card-footer'>
            <div className='footer-item'>
              <p>{data.year}</p>
            </div>
            <div className='footer-item'>
              <p>{serieName}</p>
            </div>
          </div>
        </Card>
      );

  }
  