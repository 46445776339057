import { Link } from 'react-router-dom';
import './bioCard.css'
import { useEffect, useState } from 'react';
import { getPageContent } from '../../dataModule';
import { useSelector } from 'react-redux';
import { isErrored } from 'form-data';
import { useLocation } from 'react-router-dom';
import { BioEnglish, BioSpanish } from '../../pages/Bio';

const bioEng = `Gabriel Villot was born in Santa Fé and when he was able to do so he chose to study "Plastic Arts". 
This is how he does it at the Juan Mantovani Provincial School of Visual Arts and then at the School of Design and Visual Arts at the Liceo Municipal de Santa Fe.
He has participated in numerous individual and group exhibitions, some of which he did so as a representative of the province and the city, in different places in our country (Buenos Aires, Córdoba, Santa Rosa, Ushuaia, etc.), and in Europe, in the city of Rouen, France, and in Neuchâtel and Fribourg, Switzerland.
With a sporting spirit, he participated in competitions such as the National Drawing and Engraving Hall, the Manuel Belgrano Municipal Hall, the Pro-Arte Foundation Hall of Córdoba, the National Hall of Santa Fe, the National Hall of the Sea (Mar del Plata, Buenos Aires), etc. , in which he was distinguished on several occasions.</p>
 `
const bioEsp = `Gabriel Villot nace en Santa Fé y cuando pudo hacerlo eligió estudiar "Artes Plásticas".
Es así que lo hace en la Escuela Provincial de Artes Visuales Juan Mantovani y luego en la de Diseño y Artes Visuales del Liceo Municipal de Santa Fe.
Ha participado en numerosas exposiciones individuales y colectivas, algunas de las cuales lo hizo en calidad de representante de la provincia y la ciudad, en distintos lugares de nuestro país (Bs. As., Córdoba, Santa Rosa, Ushuaia etc.), y en Europa, en la ciudad de Rouen, Francia, y en las de Neuchâtel y Fribourg, Suiza.
Con espíritu deportivo, participó en certámenes como el Salón Nacional de Dibujo y Grabado, Salón Municipal Manuel Belgrano, Salón Fundación Pro-Arte de Córdoba, Salón Nacional de Santa Fe, Salón Nacional del Mar (Mar del Plata, Bs. As.) etc, en los que fue distinguido en varias oportunidades.
`

export const BioCard = ()=> {

    const location = useLocation()
    const [bioData, setBioData] = useState()
    const lenState = useSelector(state=>state.user.lenguage)
    const path = lenState=='es'? '/biografia' : '/biography'

    useEffect(()=>{
        getPageContent(path, lenState)
        .then(res=>{
            if(res.status===200){
                setBioData(res.data)
              
            }
        })
    },[lenState, path])

    function shortDescription(cadena, longitudDeseada) {
        const palabras = cadena.split(' ');
        if (palabras.length <= longitudDeseada) {
            return cadena;
        } else {
            return palabras.slice(0, longitudDeseada).join(' ');
        }
    }
    const imgUrl = 'https://www.ate.org/data/img_cont/img_artistas/img_gr/302_1522.jpg'
    const lorem ='Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. No sólo sobrevivió 500 años, sino que tambien ingresó como texto de relleno en documentos electrónicos, quedando esencialmente igual al original. Fue popularizado en los 60s con la creación de las hojas "Letraset", las cuales contenian pasajes de Lorem Ipsum, y más recientemente con software de autoedición, como por ejem'
    return (
        <div className="bio-card">
            <div className='card-container'>
                <div className='card-img'
                    style={{backgroundImage: `url(${imgUrl})`}}
                ></div>
                <div className='card-body'>
                    <h3>{bioData ? bioData.content.title : ''}</h3>
                    <p>
                        {
                            bioData && lenState === 'es' ?
                            shortDescription(bioEsp,30)
                            : shortDescription(bioEng,30) 
                        }...
                      
                    </p>
                    <Link to={path}>{lenState==='es'?'Seguir leyendo':'Keep reading'}</Link>
                </div>
            </div>
        </div>
    )
}