import React, { useEffect, useState } from 'react';

import { deleteImageAndData, deleteImageByUrl } from './adminModule';

const Image = ({ images, src, setDeleteImg, id, selectedTab }) => {
    const [col,setCol] = useState()
    
    const notifyChanges = () => {
      console.log('img delete', images)
    }

    useEffect(()=>{

        if(selectedTab==='Pinturas'){
            setCol('paints')
        }
        if(selectedTab==='Series'){
            setCol('series')

        }
    })
  return (
    <div className="image-container">
      <img className="image" src={src} alt="imagen" />
      <button className="delete-button" onClick={()=>{
        deleteImageAndData(col,id,src)
        .then(res=>{
            console.log(res)
            if(res.status===200){
              const index = images.indexOf(src);
              if (index !== -1) {
                  images.splice(index, 1); // Eliminar la imagen del array
                  notifyChanges(); // Notificar el cambio
              }
                setDeleteImg(true)      
            }
        })
        }}>
        &#10006;
      </button>
    </div>
  );
};


export default Image;
