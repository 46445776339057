import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { handleLen, to } from '../../reducers/userReducer'
import './swich.css'

export const SwichLen = ({setMenu})=>{
    
    const lenState = useSelector(state => {return state.user.lenguage})
    const dispatch = useDispatch()
    const [len,setLen] = useState()
    
    const handleSwich = (len)=> {
        setLen(len)
        dispatch(handleLen({len}))
    }

    useEffect(()=>{
        setLen(lenState)
        
    
    },[lenState])
    return (
        <div className="swich-len">
            {
                lenState ?
                <>
                    <div onClick={()=>{handleSwich('es'); setMenu?setMenu(false):''}} className={lenState==='es'?'swich-item len':'swich-item'}>{lenState==='es'?'Español':'Spanish'}</div>
                    <div>|</div>
                    <div onClick={()=>{handleSwich('en');setMenu?setMenu(false):''}} className={lenState==='en'?'swich-item len':'swich-item'}>{lenState==='en'?'English':'Ingles'}</div>
                </> : ''
            }
            
        </div>
    )
}