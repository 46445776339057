import { useEffect, useState } from 'react';
import './bio.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getPageContent } from '../dataModule';
import { Spinner } from '../components/spinner/Spinner';
import { HelmetComponent } from '../Helmet';

export const BioEnglish = () => {
  return(
    <p className='bio-text'>
      Gabriel Villot was born in Santa Fé and when he was able to do so he chose to study "Plastic Arts". <br/>
This is how he does it at the Juan Mantovani Provincial School of Visual Arts and then at the School of Design and Visual Arts at the Liceo Municipal de Santa Fe.
He has participated in numerous individual and group exhibitions, some of which he did so as a representative of the province and the city, in different places in our country (Buenos Aires, Córdoba, Santa Rosa, Ushuaia, etc.), and in Europe, in the city of Rouen, France, and in Neuchâtel and Fribourg, Switzerland.
With a sporting spirit, he participated in competitions such as the National Drawing and Engraving Hall, the Manuel Belgrano Municipal Hall, the Pro-Arte Foundation Hall of Córdoba, the National Hall of Santa Fe, the National Hall of the Sea (Mar del Plata, Buenos Aires), etc. , in which he was distinguished on several occasions.</p>
  )
}
export const BioItemsEnglish = () => {
  return(
    <div className='bio-items' >
      <h4>Awards and distinctions:</h4>
 <p>
 2009 - First Prize for drawing of the XIII Spring Salon of the AAPS. (Santa Fe)
 </p>
 <p>
 2008 - Second Prize for Drawing acquisition, Ceres National Hall.
 </p>
 <p>
 2006 - Scholarship candidate, Annual Salon of Santa Fe.
 </p>
 <p>
 2005 - Inglesa Cultural Award Annual Salon of Santa Fe, Rosa Galisteo Museum.<br/>
 First mention National Hall of Ceres, Santa Fe Province.
 </p>
 <p>
 2000 - First Prize Acquisition, French Alliance Centennial Hall (Trip to Paris). <br/>
 Rosa Galisteo de Rodríguez Museum, Santa Fe. <br/>
  Candidate for Santa Fe Annual Salon scholarship.
 </p>
 <p>
 1999 - Candidate for Santa Fe Annual Salon scholarship.
 </p>
 <p>
 1998 - Scholarship candidate, Annual Salon of Santa Fe.
 </p>
 <p>
 1997 - Scholarship candidate, Annual Salon of Santa Fe.
 </p>
 <p>
 1997 - Scholarship candidate, Annual Salon of Santa Fe.
 </p>
 <p>
 1996 - Second Prize for Drawing Acquisition at the LVIII Annual Salon of Santa Fe. <br/>
  Scholarship candidate, Annual Salon of Santa Fe.<br/>
  Second Prize at the National Small Format Show, Buenos Aires.<br/>
  Second Prize Acquisition II National Delta Hall, Buenos Aires.<br/>
  First Prize Acquisition National Hall of Villa Constitución (Santa Fe).<br/>
  1st Mention, III National Sea Hall, Teatro Auditorio, Mar del Plata.
 </p>
 <p>
 1995 - Third Prize for Daily Drawing "El Litoral", A.A.P.S. Hall.<br/>
  Rosa Galisteo Museum, Santa Fe.<br/>
  Scholarship candidate, Annual Salon of Santa Fe.
 </p>
 <p>
 1994 - First Prize at Canal 13 Hall, Santa Fe.
 </p>
 <p>
 1992 - Banco Bica Mention, Drawing, A.A.P.S. Room, Rosa Galisteo Museum, Santa Fe.
 </p>
 <p>
 1987 - First Prize Drawing, Gálvez Art Students Hall, Santa Fe.<br/>
 First Mention, Paraná Student Hall, Entre Ríos.
 </p>
    </div>
  )
}

export const BioSpanish = () => {
  return(
    <p className='bio-text'>
      Gabriel Villot nace en Santa Fé y cuando pudo hacerlo eligió estudiar "Artes Plásticas". <br/>
Es así que lo hace en la Escuela Provincial de Artes Visuales Juan Mantovani y luego en la de Diseño y Artes Visuales del Liceo Municipal de Santa Fe.
Ha participado en numerosas exposiciones individuales y colectivas, algunas de las cuales lo hizo en calidad de representante de la provincia y la ciudad, en distintos lugares de nuestro país (Bs. As., Córdoba, Santa Rosa, Ushuaia etc.), y en Europa, en la ciudad de Rouen, Francia, y en las de Neuchâtel y Fribourg, Suiza.
Con espíritu deportivo, participó en certámenes como el Salón Nacional de Dibujo y Grabado, Salón Municipal Manuel Belgrano, Salón Fundación Pro-Arte de Córdoba, Salón Nacional de Santa Fe, Salón Nacional del Mar (Mar del Plata, Bs. As.) etc, en los que fue distinguido en varias oportunidades.
    </p>
  )
}
export const BioItemsSpanish = () => {
  return(
    <div className='bio-items' >
      <h4>Premios y distinciones:</h4>
      <p>
        2009 -	Primer Premio adquisición Dibujo del XIII Salón Primavera de la AAPS. (Santa Fe)
      </p>
      <p>
      2008 -	Segundo Premio adquisición Dibujo, Salón Nacional de Ceres.
      </p>
      <p>
      2006 -	Candidato a beca, Salón Anual de Santa Fe.
      </p>
      <p>
      2005 -	Premio Cultural Inglesa Salón Anual de Santa Fe, Museo Rosa Galisteo.<br/>
      Primera mención Salón Nacional de Ceres, Pcia. Santa Fe.
      </p>
      <p>
      2000 - Primer Premio Adquisición, Salón Centenario Alianza Francesa (Viaje a Paris). <br/>
      Museo Rosa Galisteo de Rodríguez, Santa Fe. <br/>
	    Candidato a beca Salón Anual de Santa Fe.
      </p>
      <p>
      1999 -	Candidato a beca Salón Anual de Santa Fe.
      </p>
      <p>
      1998 -	Candidato a beca, Salón Anual de Santa Fe.
      </p>
      <p>
      1997 -	Candidato a beca, Salón Anual de Santa Fe.
      </p>
      <p>
      1997 -	Candidato a beca, Salón Anual de Santa Fe.
      </p>
      <p>
      1996 -	Segundo Premio Adquisición Dibujo LVIII Salón Anual de Santa Fe. <br/>
	Candidato a beca, Salón Anual de Santa Fe.<br/>
	Segundo Premio Salón Nacional del Pequeño Formato, Buenos Aires.<br/>
	Segundo Premio Adquisición II Salón Nacional del Delta, Buenos Aires.<br/>
	Primer Premio Adquisición Salón Nacional de Villa Constitución (Santa Fe).<br/>
	1ª Mención, III Salón Nacional del Mar, Teatro Auditorio, Mar del Plata.
      </p>
      <p>
      1995 -	Tercer Premio Dibujo Diario "El Litoral", Salón de A.A.P.S.<br/>
	Museo Rosa Galisteo, Santa Fe.<br/>
	Candidato a beca, Salón Anual de Santa Fe.
      </p>
      <p>
      1994 -	Primer Premio Salón Canal 13, Santa Fe.
      </p>
      <p>
      1992 -	Mención Banco Bica, Dibujo, Salón A.A.P.S., Museo Rosa Galisteo, Santa Fe.
      </p>
      <p>
      1987 -	Primer Premio Dibujo, Salón de Estudiantes de Arte de Gálvez, Santa Fe.<br/>
      Primera Mención, Salón de Estudiantes de Paraná, Entre Ríos.
      </p>
    </div>
  )
}

export function Bio() {
  const location = useLocation();
  const [data, setData] = useState(null);
  const lenState = useSelector(state => state.user.lenguage);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let newPath;

    if (lenState === 'es') {
      newPath = '/biografia';
      navigate(newPath);
    } else if (lenState === 'en') {
      newPath = '/biography';
      navigate(newPath);
    }

    if (newPath) {
      getPageContent(newPath, lenState).then(res => {

        setData(res.data);
      });
    }
  }, [lenState, navigate]);

  return (
    <div className='page'>
        {
            !data ?
            <Spinner/> :

            <div className='bio-container'>
              <HelmetComponent title={data.content.title.charAt(0).toUpperCase()+data.content.title.slice(1)} 
              description={data.content.body}/>
                <div className='bio-header'>
                <div className='header-title'>
                    <p>{lenState === 'es' ? 'Artista' : 'Artist'}</p>
                    <h1>Gabriel Villot</h1>
                </div>
                <div className='img' style={{ backgroundImage: `url('${data ? data.content.img : ''}')` }}>
                </div>
                </div>
                <div className='bio-body'>
                <div className='title'>
                    <h2>{data ? data.content.title : ''}</h2>
                </div>
                {
                  lenState === 'es' ?
                  <>
                    <BioSpanish/>
                    <BioItemsSpanish/>
                  </> :
                  <>
                    <BioEnglish/>
                    <BioItemsEnglish/>
                  </>}

                </div>
            </div>
        }
    </div>
  );
}
