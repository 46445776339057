import React, { useEffect, useState } from 'react';
import { getAuth, signInWithEmailAndPassword, setPersistence, browserSessionPersistence, onAuthStateChanged } from 'firebase/auth'; 
import './login.css'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleHelmet } from '../reducers/userReducer';


const LoginForm = () => {
  const auth = getAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(()=>{

 
    dispatch(handleHelmet({title:'GV | Admin | Login'}))

    // Verificar si el usuario está autenticado al cargar la página
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/admin/panel'); // Redirigir si el usuario está autenticado
      }
    });

    return () => unsubscribe(); // Limpiar el efecto
  }, [dispatch, navigate, auth]);

  function handleSubmit(e) {
    e.preventDefault(); // Evita que se refresque la página
      
    try {
       // Configurar la persistencia de sesión
       
          signInWithEmailAndPassword(auth, email, password)
            .then(() => {
              sessionStorage.setItem('login', true);
              setPersistence(auth, browserSessionPersistence)
              navigate('/admin/panel');
            })
            .catch(error => {
              setError(error.message);
              console.log(error.message)
            });
       
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className='login-form-container'> 
      <h2>Iniciar sesión</h2>
      {error && <p>{error}</p>}
      <form className='login-form' onSubmit={handleSubmit}>
        <div className='input-label'>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div  className='input-label'>
          <label htmlFor="password">Contraseña:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Iniciar sesión</button>
      </form>
    </div>
  );
};

export default LoginForm;
