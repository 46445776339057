import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import  {Home}  from './pages/Home';
import  {Series}  from './pages/Series';
import  {Serie}  from './pages/Serie';
import { Paint } from './pages/Paint';
import { Bio } from './pages/Bio';
import { Expositions } from './pages/Expositions';
import { Contact } from './pages/Contact';
import { Cart } from './pages/Cart';
import {Admin} from './pages/Admin';
import { Error } from './pages/Error';

import { Provider, useDispatch, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';

import Navbar from './components/navbar/Navbar';
import Summary from './components/summary/Summary';
import { Footer } from './components/footer/Footer';
import { Notification } from './components/notification/Notification';
import LoginForm from './adminComponents/LoginForm';
import AdminPanel from './adminComponents/AdminPanel';
import { HelmetComponent } from './Helmet';
import { useEffect, useState } from 'react';
import { handleLen } from './reducers/userReducer';
import NewPanel from './adminComponents/newPanel';
import WppButton from './components/WppButton/WppButton';

const SetLen = () => {
  const [language,setLanguage] = useState()
  const userLanguage = (navigator.language || navigator.userLanguage);
  const lenState = useSelector(state=>state.user.lenguage)
  const dispatch = useDispatch()
  
  useEffect(()=>{
      
        if (userLanguage==='es-ES' || userLanguage==='es' ) {
            setLanguage('es');
            dispatch(handleLen({len:'es'}))
         
          } else {
            setLanguage('en');
            dispatch(handleLen({len:'en'}))
       
          }  
          console.log(userLanguage)
  }, [userLanguage])
}

function App() {

  
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App">
          <SetLen/>
          <Router>
            <Navbar/>
            <Notification/>
            <WppButton/>
       
            <Routes>
                <Route exact path="/" element={<Home/>} />
                <Route path={'/series'} element={<Series/>} />
                <Route path="/:serieName" element={<Serie/>} />
                <Route  path="/:serieName/:paintName" element={<Paint/>} />
                <Route  path="/biografia" element={<Bio/>} />
             
                <Route  path="/contacto" element={<Contact/>} />
              
                <Route  path={'/admin'} element={<LoginForm/>} />
                <Route  path={'/admin/login'} element={<LoginForm/>} />
                <Route  path="/admin/panel" element={<NewPanel/>} />

              
                <Route path={'/collections'} element={<Series/>} />
                <Route  path="/biography" element={<Bio/>} />
   
                <Route  path="/contact" element={<Contact/>} />

                <Route  path="/404" element={<Error/>} />
            </Routes>
            <Summary/>
            <Footer/>
          </Router>
        </div>   
    </PersistGate>

    </Provider>
  );
}

export default App;
