import React, { useEffect, useState } from 'react'
import { SerieCard } from '../components/serieCard/SerieCard'
import { BuyInfoCard } from '../components/buyInfoCard/BuyInfoCard'
import { ContactCard } from '../components/contactCard/ContactCard'
import {listCollection} from '../dataModule'
import { Spinner } from '../components/spinner/Spinner'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { HelmetComponent } from '../Helmet'
import { Contact } from './Contact'
export function Series (){

    const [series, setSeries] = useState()
    const [helmetTag, setHelmetTags] = useState({title:'',description:''})
    const lenState = useSelector(state => state.user.lenguage)
    const navigate = useNavigate()
    
    useEffect(()=>{
        window.scrollTo(0,0)
    }, [])
    useEffect(()=>{
        // aca traigo la data de las series desde el servidor
        listCollection(`series-${lenState}`).
        then((res)=>{
          
            setSeries(res.list)
            if(lenState==='es'){
                navigate('/series')
                setHelmetTags({title:`Series`, description:'Descripcion de series'})
            }
            if(lenState==='en'){
                navigate('/collections')
                setHelmetTags({title:`Collections`, description:'Collections description'})
            }

        })
    },[lenState])
    
    return(
        <div className='page'>
            {
                helmetTag.title?
                <HelmetComponent title={helmetTag.title} description={helmetTag.description}/>
                : <></>
            }
            {
                series ? 
                series.map((serie, idx)=>{
                    const key = serie.id
                    return (
                        <SerieCard code={serie.id} images={serie.data.images} title={serie.data.title} description={serie.data.description}  />
                    )
                })
                : <Spinner/>
            }
            <BuyInfoCard/>
            <Contact/>
        </div>
    )
}