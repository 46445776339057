// crear clase dataManagear para manejar las peticiones a cada endpoint
export class DataManagear {

    constructor() {
        this.bio='';
        this.expositions=[];
        this.series=[];
        this.paints=[];
    }

}

export const dataManagear = new DataManagear()