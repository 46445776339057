import { getStorage,ref, listAll, getDownloadURL, deleteObject } from 'firebase/storage';
import { getFirestore, collection, addDoc,getDoc, getDocs, deleteDoc, updateDoc, doc } from "firebase/firestore";
import { firebaseApp } from './firebaseApp';
import { normalizeUrl } from './normalize';

const normalizeString = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const listCollection = async (col) => {
    try {
        const db = getFirestore(firebaseApp);
        const myCollection = collection(db, col);
        const collectionSnapshot = await getDocs(myCollection);
        const list = [];
        collectionSnapshot.forEach((doc) => {
            
            list.push({id:doc.id,data:doc.data()});

        });
        return { status: 200, list };
    } catch (err) {
        return { status: 403, err: err };
    }
}
export const getSerieNameById = async (serieId, len) => {
    try {
      
        const esSeries = await listCollection('series-es')
        const enSeries = await listCollection('series-en')

        const esSerie = esSeries.list.find((serie)=>{
            return serie.id===serieId
        })
        const enSerie = enSeries.list.find((serie)=>{
            return serie.id===serieId
        })
        if(len==='es'){
            if(esSerie){
                return { status: 200, serieName: esSerie.data.title };
            }
            if(enSerie){
                const esSerie = esSeries.list.find((serie)=>{
                    return serie.id===enSerie.data.key
                })
                return { status: 200, serieName: esSerie.data.title };
            }

        }
        
        if(len==='en'){
            if(enSerie){
                return { status: 200, serieName: enSerie.data.title };
            }
            if(esSerie){
                const enSerie = enSeries.list.find((serie)=>{
                    return serie.data.key===esSerie.id
                })
                return { status: 200, serieName: enSerie.data.title };
            }
        }
       
    } catch (err) {
        return { status: 500, err: err };
    }
};
export const getPaintsInSerie = async (serieId, len) => {
    try {
        const esList = await listCollection('series-es')
        const enList = await listCollection('series-en')

        const esSerie = esList.list.find(serie => serie.id === serieId)
        const enSerie = enList.list.find(serie => serie.id === serieId)

        if(len==='es'){
            const paintsList = await listCollection('paints-es')
           
            if(esSerie){
                const seriePaints = paintsList.list.filter((paint)=>{
                    
                    return paint.data.serie===esSerie.id
                })
             
                
                return { status: 200, paints:seriePaints};
            }
            if(!esSerie && enSerie){
             
                const correctSerie = esList.list.find((serie)=>{
                    return serie.id === enSerie.data.key
                })
                const paints = paintsList.list.filter((paint)=>{
                    return paint.data.serie === correctSerie.id
                })
          
                return { status: 200, paints:paints};
            }
        }
        if(len==='en'){
            const paintsList = await listCollection('paints-en')
 
            if(enSerie){
                const seriePaints = paintsList.list.filter((paint)=>{
                 
                    return paint.data.serie===enSerie.id
                })
             
                
                return { status: 200, paints:seriePaints};
            }
            if(!enSerie && esSerie){
              
                const correctSerie = enList.list.find((serie)=>{
                    return serie.data.key === esSerie.id
                })
                const paints = paintsList.list.filter((paint)=>{
                    return paint.data.serie === correctSerie.id
                })
         
                return { status: 200, paints:paints};
            }
        }
    } catch (err) {
        return { status: 403, err: err };
    }
};

export const getPaint = async (paintName, serieName, len) => {
    try {
        const seriesCollection = await listCollection(`series-${len}`);
        const paintsCollection = await listCollection(`paints-${len}`);
        
        const paint = findItemByTitle(paintsCollection.list, paintName);
        const serie = findItemByTitle(seriesCollection.list, serieName);

        if (paint && serie) {
            return { status: 200, paint, serie };
        }

        const alternateLen = len === 'es' ? 'en' : 'es';
        const alternateSeriesCollection = await listCollection(`series-${alternateLen}`);
        const alternatePaintsCollection = await listCollection(`paints-${alternateLen}`);

        const alternateSerie = findItemByTitle(alternateSeriesCollection.list, serieName);
        const alternatePaint = findItemByTitle(alternatePaintsCollection.list, paintName);

        if (alternateSerie && alternatePaint) {
            const matchedSerie = findItemById(seriesCollection.list, alternateSerie.data.key);
            const matchedPaint = findItemBySerieAndTitle(paintsCollection.list, matchedSerie.id, alternatePaint.data.title);

            if (matchedSerie && matchedPaint) {
                return { status: 200, paint: matchedPaint, serie: matchedSerie };
            }
        }

        return { status: 404, message: "Document not found" };
    } catch (err) {
        return { status: 500, err };
    }
};

const findItemByTitle = (list, title) => {
    return list.find(item => normalizeUrl(item.data.title) === normalizeUrl(title));
};

const findItemById = (list, id) => {
    return list.find(item => item.id === id);
};

const findItemBySerieAndTitle = (list, serieId, title) => {
    return list.find(item => item.data.serie === serieId && normalizeUrl(item.data.title) === normalizeUrl(title));
};

export const addDocumentToCollection = async (col, data) => {
    try {
        const db = getFirestore(firebaseApp);
        const collectionRef = collection(db, col);
        
        await addDoc(collectionRef, data);
        
        return { status: 200, message: "Document added successfully." };
    } catch (err) {
        return { status: 500, err: err };
    }
}

export const getDocument = async (col,lan) => {
    try {
        

        const { status, list } = await listCollection(col); // Replace 'your_collection_name' with the name of your collection
  
        if (status === 200) {
            const doc = list.find(doc => doc.data.lan === lan);
            
            if (doc) {
                return { status: 200, data: doc };
            } else {
                return { status: 404, message: "Document not found" };
            }
        } else {
            return { status: 500, message: "Error retrieving collection" };
        }
    } catch (err) {
        return { status: 500, err: err };
    }
}

export const getPageContent = async (path, lan) => {
    try {
        const { status, data } = await getDocument('content', lan);
        const document = data
   
        if (status === 200) {
            const pages = data.data.pages
            for(const key in pages){
                
                const comparation = path === pages[key].path
                if(comparation===true){
                    return {status:200, data:pages[key]}
                }
                    
            }
            return {status:400}
        } else {
            return { status: 500, message: "Error retrieving document" };
        }
    } catch (err) {
        return { status: 500, err: err };
    }
};


export const getSerieByPath = async (path,lenState) => {
    try {
        const esRes = await listCollection('series-es')
        const enRes = await listCollection('series-en')
            
        const esSerie = esRes.list.find(serie => `/${normalizeUrl(serie.data.title)}` === path);
        const enSerie = enRes.list.find(serie => `/${normalizeUrl(serie.data.title)}` === path);

        if(lenState ==='es'){
            if(esSerie){
                return { status: 200, data:esSerie };
            }
            if(!esSerie && enSerie) {
                const serie = esRes.list.find((serie)=>{
                    return serie.id===enSerie.data.key
                })
                return { status: 200, data:serie };
            }
        }
        if(lenState ==='en'){
     
            if(enSerie){
                return { status: 200, data:enSerie };
            }
            if(!enSerie && esSerie) {
                const serie = enRes.list.find((serie)=>{
                   
                    return serie.data.key===esSerie.id
                })
                return { status: 200, data:serie };
            }
        }
        return { status: 200, data };
       
    } catch (err) {
        return { status: 500, err: err };
    }
};
